<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  computed: {
    isFwdt() {
      const url = window.location.pathname.toUpperCase();
      return url === '/SERVICEHALL';
    },
  },
  methods: {},
};
</script>

<style lang="scss">
#app {
  height: 100vh;
  min-width: 1200px;
  font-family: 'Microsoft YaHei', 'SimHei', 'Heiti SC';
}
</style>
