import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/',
    component: function () {
      return import('@/page/index')
    },
    children: [
      {
        path: '/',
        name: 'index',
        component: () =>
          import( /* webpackChunkName: "dashboard" */ '@/views/serviceHall'),
      },
      {
        path: '/index',
        name: 'index',
        component: () =>
          import( /* webpackChunkName: "dashboard" */ '@/views/serviceHall'),
      },
      {
        path: '/zsdy',
        name: 'zsdy',
        component: () =>
          import( /* webpackChunkName: "serviceList" */ '@/views/zsdy'),
      }
    ]
  },
  {
    path: '/404',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/404'),
    name: '404',
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
